.skill-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates 4 columns */
    gap: 10px; /* Adjust the gap between items as needed */
  }
  
  /* Additional styling can be added to customize appearance */
  .skill-grid label {
    display: block;
  }
  